import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import {
  getAllGameTags,
  deleteGameTag
} from '@services/gameTag/GameTagService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { Button, Card, LinearProgress } from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/shared/Loader/Loader'

// TYPES
import { GameTag } from '@shared/types/GameTagTypes'

// STYLES
import * as Styled from './GameTagsList.styles'

const GameTagsList = (): JSX.Element => {
  const [gameTags, setGameTags] = useState<GameTag[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    _getGameTags()
  }, [])

  const _getGameTags = async (): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const gameTagsData: GameTag[] = await getAllGameTags()
      setGameTags(gameTagsData)
      setRowCount(gameTagsData.length)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const _deleteGameTag = async (id: number): Promise<void> => {
    Alert.remove('Tem certeza que deseja excluir o marcador?')
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteGameTag(id)
          Alert.success(
            'Marcador excluído com sucesso',
            removeGameTagFromState(id)
          )
        }
      })
      .catch((e) => {
        Alert.error(`Ocorreu um erro ao excluir o marcador: ${e.errors}`)
      })
  }

  const removeGameTagFromState = (id: number): void => {
    const newGameTags = gameTags.filter(
      (tag) => tag.id !== id
    )
    setGameTags(newGameTags)
  }
  
  const renderCustomLoadingOverlay = ():JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    );
  }

  const renderActionButtons = (id: number): JSX.Element => {
    return (
      <>
        <Button
          onClick={(): void => navigate(`/apps/mobile/appdiscovery/gameTags/${id}/edit`)}
          color="primary"
          variant="contained"
          sx={{ mr: 1 }}
        >
          Editar
        </Button>
        <Button
          onClick={(): Promise<void> => _deleteGameTag(id)}
          color="error"
          variant="contained"
        >
          Excluir
        </Button>
      </>
    )
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      maxWidth: 200
    },
    {
      field: 'name_pt_br',
      headerName: 'Marcador',
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 300,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Marcadores">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
    {gameTags?.length > 0 ? (
      <Card
        sx={{
          mt: 4,
          ['& .MuiDataGrid-iconSeparator']: {
            display: 'none'
          }
        }}
      >
        <DataGrid
          paginationMode="client"
          rowCount={rowCount}
          rows={gameTags}
          columns={columns}
          loading={refreshDataLoading}
          getRowId={(row) => row.id}
          pageSize={10}
          rowsPerPageOptions={[4]}
          autoHeight
          rowHeight={50}
          disableSelectionOnClick
          components={{
            LoadingOverlay: renderCustomLoadingOverlay,
          }}
          sx={{
            ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
              outline: 'none'
            },
            ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
              whiteSpace: 'break-spaces'
            },
          }}
        />
      </Card>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { GameTagsList }
