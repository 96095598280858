// API
import { mobileApi } from '@shared/api/mobile/mobileApi'

// TYPES
import {
  GameTag,
  GameTagPayload
} from '@shared/types/GameTagTypes'

const getAllGameTags = (): Promise<GameTag[]> => {
  return mobileApi.get(`/backoffice/games/tags`)
}

const createGameTag = (data: Pick<GameTag, 'name_pt_br'>): Promise<GameTag> => {
  return mobileApi.post(`/backoffice/games/tags`, data)
}

const updateGameTag = (gameTagId: number, data: Pick<GameTag, 'name_pt_br'>): Promise<GameTag> => {
  return mobileApi.patch(`/backoffice/games/tags/${gameTagId}`, data)
}

const getGameTagById = (gameTagId: number): Promise<GameTag> => {
  return mobileApi.get(`/backoffice/games/tags/${gameTagId}`)
}

const deleteGameTag = (gameTagId: number): Promise<void> => {
  return mobileApi.delete(`/backoffice/games/tags/${gameTagId}`)
}

export {
  getAllGameTags,
  createGameTag,
  updateGameTag,
  getGameTagById,
  deleteGameTag,
}
