import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControlLabel,
  Switch,
  Checkbox,
  Stack,
  Box,
  Autocomplete,
  Tabs,
  Tab,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Typography,
  FormControl,
  Chip
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { AddCircleOutlineRounded as AddIcon }  from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

// SERVICES
import { createGameReviewTag, getGameReviewTagById, updateGameReviewTag } from '@services/gameReviewTag/GameReviewTagService'

// STYLES
import * as Styled from './GameReviewTagsForm.styles'

const GameReviewTagsForm = (): JSX.Element => {
  const [name_pt_br, setName_pt_br] = useState<string>('')
  const [name_en_us, setName_en_us] = useState<string>('')
  const [name_es_es, setName_es_es] = useState<string>('')
  const [loading, setLoading] = useState(false)
  
  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    if (id) {
      _getGameReviewTag()
    }
  }, [id])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (id) {
      _updateGameReviewTag() 
    } else {
      _createGameReviewTag()
    }
  }

  const payload = id 
  ? { name_pt_br, name_en_us, name_es_es, id } 
  : { name_pt_br, name_en_us, name_es_es }
  
  const _createGameReviewTag = async (): Promise<void> => {
    try {
      setLoading(true)
      await createGameReviewTag(payload)

      Alert.success(
        'Marcador de Review criado com sucesso',
        navigate('/apps/mobile/appdiscovery/gameReviewTags/list')
      )
    } catch (err) {
      Alert.error(`Ocorreu um erro ao realizar a criação do marcador de review. Erro: ${err.errors}`)
    }
  }

  const _updateGameReviewTag = async (): Promise<void> => {
    try {
      setLoading(true)
      await updateGameReviewTag(Number(id), payload)

      Alert.success(
        'Marcador de Review atualizado com sucesso',
        navigate('/apps/mobile/appdiscovery/gameReviewTags/list')
      )
    } catch (err) {
      Alert.error(`Ocorreu um erro ao realizar a alteração do marcador de review. Erro: ${err.errors}`)
    }
  }

  const _getGameReviewTag = async (): Promise<void> => {
    try {
      const gameReviewTagData = await getGameReviewTagById(Number(id))

      if (gameReviewTagData) {
        setName_pt_br(gameReviewTagData.name_pt_br)
        setName_en_us(gameReviewTagData.name_en_us)
        setName_es_es(gameReviewTagData.name_es_es)
      }
    } catch (err) {
      Alert.error(`Ocorreu um erro ao buscar o marcador: ${err.errors}`)
    }
  }

  return (
    <Styled.Container>
        <form onSubmit={handleSubmit}> 
          <Card sx={{ mb: 2 }}>
            <CardHeader title={
              <HeaderTitle title={`${formTitleAction} Marcador de Review`}>
                <AddIcon fontSize="large" style={{ fill: '#0AB844'}}/>
              </HeaderTitle>
            }/>
            <CardContent>
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={4}>
                  <TextField
                    key="name_pt_br"
                    label="Nome do Marcador (PT)"
                    style={{ width: '100%' }}
                    value={name_pt_br}
                    onChange={(e) => setName_pt_br(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key="name_en_us"
                    label="Nome do Marcador (EN)"
                    style={{ width: '100%' }}
                    value={name_en_us}
                    onChange={(e) => setName_en_us(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key="name_es_es"
                    label="Nome do Marcador (ES)"
                    style={{ width: '100%' }}
                    value={name_es_es}
                    onChange={(e) => setName_es_es(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs sx={{ mt: 3 }}>
                  <Box
                    display="flex"
                    justifyContent={{ xs: 'flex-end', sm: 'flex-end' }}
                  >
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      disabled={loading || !name_pt_br || !name_en_us || !name_es_es}
                      loading={loading}
                      >
                      {buttonLabelAction} marcador
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
    </Styled.Container>
  )
}

export { GameReviewTagsForm }
