// API
import { mobileApi } from '@shared/api/mobile/mobileApi'

// TYPES
import {
  CategoryResponse,
  CategoryPayload,
  Category
} from '@shared/types/CategoryTypes'

const getAllCategories = (): Promise<Category[]> => {
  return mobileApi.get(`/backoffice/categories`)
}

const createCategory = (data: CategoryPayload): Promise<CategoryResponse> => {
  return mobileApi.post(`/backoffice/categories`, data)
}

const updateCategory = (categoryId: number, data: CategoryPayload): Promise<CategoryResponse> => {
  return mobileApi.patch(`/backoffice/categories/${categoryId}`, data)
}

const getCategoryById = (categoryId: number): Promise<CategoryResponse> => {
  return mobileApi.get(`/backoffice/categories/${categoryId}`)
}

const deleteCategory = (categoryId: number): Promise<void> => {
  return mobileApi.delete(`/backoffice/categories/${categoryId}`)
}

export {
  getAllCategories,
  getCategoryById,
  createCategory,
  updateCategory,
  deleteCategory,
}
