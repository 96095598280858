import { useState } from 'react'

import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse
} from '@mui/material'

import {
  SportsEsports,
  ExpandLess,
  ExpandMore,
  AddCircleOutlineRounded as AddCircleOutlineRoundedIcon,
  AssignmentRounded as AssignmentRoundedIcon,
  CategoryRounded as CategoryIcon,
  ThumbsUpDownRounded as RatingsIcon,
  StyleRounded as TagsIcon
} from '@mui/icons-material'

// STYLES
import * as Styled from './Sidebar.styles'

const SideBar = (): JSX.Element => {
  const drawerWidth = 250

  const menuItemsWithSubItem = [
    {
      id: 1,
      name: 'Jogos',
      icon: <SportsEsports />,
      subItems: [
        {
          name: 'Listagem de Games',
          link: '/apps/mobile/appdiscovery/games/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar Games',
          link: '/apps/mobile/appdiscovery/games/create',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 2,
      name: 'Categorias',
      icon: <CategoryIcon />,
      subItems: [
        {
          name: 'Listagem de Categorias',
          link: '/apps/mobile/appdiscovery/categories/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar Categoria',
          link: '/apps/mobile/appdiscovery/categories/create',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ]
    },
    {
      id: 3,
      name: 'Reviews',
      icon: <RatingsIcon />,
      subItems: [
        {
          name: 'Listagem de Reviews',
          link: '/apps/mobile/appdiscovery/reviews/list',
          icon: <AssignmentRoundedIcon />,
        }
      ]
    },
    {
      id: 4,
      name: 'Marcadores',
      icon: <TagsIcon />,
      subItems: [
        {
          name: 'Listagem de Marcadores',
          link: '/apps/mobile/appdiscovery/gameTags/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar Marcador',
          link: '/apps/mobile/appdiscovery/gameTags/create',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ]
    },
    {
      id: 5,
      name: 'Marcadores de Review',
      icon: <TagsIcon />,
      subItems: [
        {
          name: 'Listagem de Marcadores de Review',
          link: '/apps/mobile/appdiscovery/gameReviewTags/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar Marcador de Review',
          link: '/apps/mobile/appdiscovery/gameReviewTags/create',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ]
    },
  ]

  const [isMenuVisible, setIsMenuVisible] = useState(
    menuItemsWithSubItem.reduce(
      (menuItem, { id }) => ({
        ...menuItem,
        [id]: false,
      }),
      {}
    )
  )

  const handleClick = (id: number): void => {
    setIsMenuVisible({
      ...isMenuVisible,
      [id]: !isMenuVisible[id],
    })
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        ['& .MuiDrawer-paper']: {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#0AB844',
        },
      }}
    >
      <Toolbar sx={{ mt: 2 }} />
      <Styled.MenuContainer>
        <Styled.MenuContent>
          {menuItemsWithSubItem.map((item, itemsIndex) => (
            <List component="nav" key={itemsIndex} disablePadding>
              <ListItemButton onClick={(): void => handleClick(item.id)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.name}
                  disableTypography
                  sx={{ fontWeight: '300' ,fontSize: '0.875rem' }}
                />
                {isMenuVisible[item.id] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={isMenuVisible[item.id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.subItems.map((subItem, subItemIndex) => (
                    <Styled.LinkMenu key={subItemIndex} to={subItem.link}>
                      <ListItem button key={subItem.name} sx={{ pl: 4 }}>
                        <ListItemIcon>{subItem.icon}</ListItemIcon>
                        <ListItemText
                          primary={subItem.name}
                          disableTypography
                          sx={{ fontWeight: '300',fontSize: '0.875rem'  }}
                        />
                      </ListItem>
                    </Styled.LinkMenu>
                  ))}
                </List>
              </Collapse>
            </List>
          ))}

          {/* descomentar caso venha a ter menu sem submenu
          <List component="nav" disablePadding>
            <List component="div" disablePadding>
              {menuItems.map((item) => (
                <Styled.LinkMenu key={item.id} to={item.link}>
                  <ListItem button sx={{ pl: 2 }}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      disableTypography
                      sx={{ fontWeight: '300' }}
                    />
                  </ListItem>
                </Styled.LinkMenu>
              ))}
            </List>
          </List> */}
        </Styled.MenuContent>

        <Styled.FooterContainer>
            <Styled.Divider />
          <Styled.Footer>
            &copy; {new Date().getFullYear()}{' '}
            <Styled.FooterLink href="http://gamersclub.com.br" target="_blank">
              GamersClub
            </Styled.FooterLink>{' '}
            made with ❤️ for a better backoffice
          </Styled.Footer>
        </Styled.FooterContainer>
      </Styled.MenuContainer>
    </Drawer>
  )
}

export { SideBar }