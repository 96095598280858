import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import {
  getAllCategories,
  deleteCategory
} from '@services/category/CategoryService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { Button, Card, LinearProgress } from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/shared/Loader/Loader'

// TYPES
import { CategoryResponse } from '@shared/types/CategoryTypes'

// STYLES
import * as Styled from './CategoryList.styles'

const CategoryList = (): JSX.Element => {
  const [categories, setCategories] = useState<CategoryResponse[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    _getCategories()
  }, [])

  const _getCategories = async (): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const categoriesData: CategoryResponse[] = await getAllCategories()
      setCategories(categoriesData)
      setRowCount(categoriesData.length)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const _deleteCategory = async (id: number): Promise<void> => {
    Alert.remove('Tem certeza que deseja excluir a categoria?')
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteCategory(id)
          Alert.success(
            'Categoria excluída com sucesso',
            removeCategoryFromState(id)
          )
        }
      })
      .catch((e) => {
        Alert.error('Ocorreu um erro ao excluir a categoria')
      })
  }

  const removeCategoryFromState = (id: number): void => {
    const newCategories = categories.filter(
      (category) => category.id !== id
    )
    setCategories(newCategories)
  }
  
  const renderCustomLoadingOverlay = ():JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    );
  }

  const renderActionButtons = (id: number): JSX.Element => {
    return (
      <>
        <Button
          onClick={(): void => navigate(`/apps/mobile/appdiscovery/categories/${id}/edit`)}
          color="primary"
          variant="contained"
          sx={{ mr: 1 }}
        >
          Editar
        </Button>
        <Button
          onClick={(): Promise<void> => _deleteCategory(id)}
          color="error"
          variant="contained"
        >
          Excluir
        </Button>
      </>
    )
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      maxWidth: 200
    },
    {
      field: 'name_pt_br',
      headerName: 'Categoria',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 300,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Categorias">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
    {categories?.length > 0 ? (
      <Card
        sx={{
          mt: 4,
          ['& .MuiDataGrid-iconSeparator']: {
            display: 'none'
          }
        }}
      >
        <DataGrid
          paginationMode="server"
          rowCount={rowCount}
          rows={categories}
          columns={columns}
          loading={refreshDataLoading}
          getRowId={(row) => row.id}
          pageSize={10}
          rowsPerPageOptions={[4]}
          autoHeight
          rowHeight={80}
          disableSelectionOnClick
          components={{
            LoadingOverlay: renderCustomLoadingOverlay,
          }}
          sx={{
            ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
              outline: 'none'
            },
            ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
              whiteSpace: 'break-spaces'
            },
          }}
          onPageChange={(): Promise<void> => _getCategories()}
        />
      </Card>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { CategoryList }
