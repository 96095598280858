import axios from 'axios'

const getYoutubeChannelId = async (channelName) => {
  try {
    const response = await axios.get(`https://www.youtube.com/@${channelName}`)

    const regex = /channelId":"(.+?)"/gm
    const match = regex.exec(response.data)
    if (!match) {
      throw new Error('Channel ID not found')
    }

    const channelId = match[1]
    try {
      await axios.get(`https://www.youtube.com/channel/${channelId}`)
      return channelId
    } catch (error) {
      console.error('Error getting Youtube channelId for channelName: ', channelName)
      console.error(error)
      return undefined
    }
  } catch (error) {
    console.error('Error getting Youtube channelId for channelName: ', channelName)
    console.error(error)
    return undefined
  }
}

export {
  getYoutubeChannelId,
}
