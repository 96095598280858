// API
import { mobileApi } from '@shared/api/mobile/mobileApi'

// TYPES
import {
  DeviceProps,
  GameData,
} from '@shared/types/SharedTypes'

const getGameData = (platform: string, gameId: string): Promise<DeviceProps> => {
  return mobileApi.get(`/backoffice/games/from-store?platform=${platform}&gameStoreId=${gameId}`)
}

const getAllGames = (): Promise<GameData[]> => {
  return mobileApi.get('/backoffice/games')
}

const createGame = (data: GameData): Promise<GameData> => {
  return mobileApi.post(`/backoffice/games`, data)
}
const updateGame = (id: string, data: GameData): Promise<GameData> => {
  return mobileApi.patch(`/backoffice/games/${id}`, data)
}

const getGameById = (id: string): Promise<GameData> => {
  return mobileApi.get(`/backoffice/games/${id}`)
}
export {
  getGameData,
  getAllGames,
  createGame,
  updateGame,
  getGameById,
}
