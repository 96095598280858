import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import {
  getAllGames
} from '@services/mobile/MobileService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { Grid, Button, Card, LinearProgress, Typography, Chip, styled } from '@mui/material'
import { DataGrid, GridColumns, GridOverlay, GridToolbar } from '@mui/x-data-grid'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/shared/Loader/Loader'

// TYPES
import { GameData } from '@shared/types/SharedTypes'

// STYLES
import * as Styled from './GamesList.styles'

const GamesList = (): JSX.Element => {
  const [games, setGames] = useState<GameData[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)
  const [gameFilter, setGameFilter] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    _getGames()
  }, [])

  const _getGames = async (): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const gamesData: GameData[] = await getAllGames()
      setGames(gamesData)
      setRowCount(gamesData.length)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const renderCustomLoadingOverlay = ():JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    );
  }

  const renderActionButtons = (id: number): JSX.Element => {
    return (
      <Button
        onClick={(): void => navigate(`/apps/mobile/appdiscovery/games/${id}/edit`)}
        color="primary"
        variant="contained"
        sx={{ mr: 1 }}
      >
        Editar
      </Button>
  )
  }

  const renderImage = (image): JSX.Element | null => {
    if (!image) {
      return null
    }

    return (
      <Styled.GameImage
        alt="Imagem do Produto"
        src={image}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src =
            'https://assets.gamersclub.com.br/header/img/gamers_club_logo_small.8a9ff3c5.svg'
        }}
      />
    )
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      maxWidth: 200
    },
    {
      field: 'title',
      headerName: 'Jogo',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'left',
      minWidth: 300,
      renderCell: (params) => {
        const { imageUrl, title } = params.row
        return (
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
          >
            <Grid item xs>
              {renderImage(imageUrl)}
            </Grid>
            <Grid item xs>
              <Typography variant="caption" display="block" gutterBottom>
                {title}
              </Typography>
            </Grid>
          </Grid>
        )
      }
    },
    {
      field: 'rank',
      headerName: 'Ranking',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      renderCell: (params) => {
        const { rank } = params.row
        return (
          <Typography variant="caption" display="block" gutterBottom>
            {rank ? rank : 'Não informado'}
          </Typography>
        )
      }
    },
    {
      field: 'publisher',
      headerName: 'Publisher',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      minWidth: 150,
    },
    {
      field: 'categories',
      headerName: 'Categorias',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      renderCell: params => params.row.categories.map((c) => c.name).join(', ')
    },
    {
      field: 'android',
      headerName: 'Id Android',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography variant="caption" display="block" gutterBottom>
            {params.row?.android ? params.row?.android.idStore : 'sem id cadastrado'}
          </Typography>
        )
      }
    },
    {
      field: 'ios',
      headerName: 'Id Apple',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 300,
      renderCell: (params) => {
        return (
          <Typography variant="caption" display="block" gutterBottom>
            {params.row?.ios ? params.row?.ios.idStore : 'sem id cadastrado'}
          </Typography>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Jogos">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
    {games?.length > 0 ? (
      <Card
        sx={{
          mt: 4,
          ['& .MuiDataGrid-iconSeparator']: {
            display: 'none'
          }
        }}
      >
        <DataGrid
          paginationMode="client"
          rowCount={rowCount}
          rows={games}
          columns={columns}
          loading={refreshDataLoading}
          getRowId={(row) => row.id}
          pageSize={10}
          rowsPerPageOptions={[4]}
          autoHeight
          rowHeight={80}
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: 'rank', sort: 'asc' }],
            },
          }}
          components={{
            LoadingOverlay: renderCustomLoadingOverlay,
            Toolbar: GridToolbar,
          }}
          filterMode='client'
          filterModel={{
            items: [{ columnField: 'title', operatorValue: 'contains', value: gameFilter }],
          }}
          onFilterModelChange={model => setGameFilter(model.items[0].value)}
          sx={{
            ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
              outline: 'none'
            },
            ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
              whiteSpace: 'break-spaces'
            },
          }}
        />
      </Card>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { GamesList }
