import { Category } from "@shared/types/CategoryTypes";
import { GameTag } from "./GameTagTypes";

export interface GameData {
  title_pt_br?: string;
  title_en_us?: string;
  title_es_es?: string;
  imageUrl?: string;
  backgroundUrl?: string;
  rank?: number;
  twitchId?: number;
  description_pt_br?: string;
  description_en_us?: string;
  description_es_es?: string;
  publisher?: string;
  categories?: number[] | Category[];
  tags?: number[] | GameTag[];
  android?: DeviceProps;
  ios?: DeviceProps;
  newsFeedSettings?: NewsFeedProps[];
  isActive: boolean;
  hasNetzyn: boolean;
}

export interface NewsFeedProps {
  id?: number;
  source?: string;
  metadata?: NewsFeedMetadataProps;
}

export interface NewsFeedMetadataProps {
  username?: string;
  indexationType: string;
  values: string[];
  
  channelId?: string;
}

export enum NewsFeedSource {
  twitter = 'twitter',
  youtube = 'youtube'
}

export enum TweetsIndexationType {
  ALL = 'ALL',
  KEYWORDS = 'KEYWORDS',
  HASHTAGS = 'HASHTAGS',
}

export enum YoutubeVideosIndexationType {
  ALL = 'ALL',
  KEYWORDS = 'KEYWORDS',
  HASHTAGS = 'HASHTAGS',
}

export interface DeviceProps {
  title?: string;
  imageUrl?: string;
  backgroundUrl?: string;
  description?: string;
  publisher?: string;
  idStore: string;
  url: string;
  installs: string;
  reviews: number;
  score: number;
  ratings: number;
  size?: number;
  categories?: string[];
  supportedDevices: string[];
  contentRating: number;
}

export const initialGameState: GameData = {
  title_pt_br: '',
  title_en_us: '',
  title_es_es: '',
  imageUrl: '',
  backgroundUrl: '',
  rank: undefined,
  description_pt_br: '',
  description_en_us: '',
  description_es_es: '',
  publisher: '',
  android: {
    idStore: '',
    url: '',
    installs: '',
    reviews: undefined,
    score: undefined,
    ratings: undefined,
    size: 0,
    supportedDevices: [],
    contentRating: 0
  },
  ios: {
    idStore: '',
    url: '',
    installs: '',
    reviews: undefined,
    score: undefined,
    ratings: undefined,
    size: 0,
    supportedDevices: [],
    contentRating: 0
  },
  categories: [],
  tags: [],
  newsFeedSettings: [
    {
      source: '',
      metadata: {
        username: '',
        indexationType: '',
        values: [],

        channelId: '',
      }
    }
  ],
  isActive: true,
  hasNetzyn: false
}

export interface GetDeviceData {
  title: string;
  imageUrl: string;
  backgroundUrl: string;
  idStore: string | number;
  url: string;
  installs?: string;
  reviews: number;
  score: number;
  ratings?: number;
  supportedDevices: string[]
}
