import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import {
  getAllReviews,
  updateReview
} from '@services/review/ReviewService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { Button, Card, LinearProgress, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { AssignmentRounded as AssignmentRoundedIcon, Search } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/shared/Loader/Loader'

// TYPES
import { Review, ReviewResponse, QueryParams } from '@shared/types/ReviewTypes'

// STYLES
import * as Styled from './ReviewsList.styles'
import { formatDate } from '@shared/utils/FormatDate'
import { LoadingButton } from '@mui/lab'

const ReviewsList = (): JSX.Element => {
  const [reviews, setReviews] = useState<Review[]>([])
  const [gameId, setGameId] = useState<number>(undefined)
  const [search, setSearch] = useState<boolean>(false)
  const [rowCount, setRowCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  useEffect(() => {
    if (gameId) {
      setSearch(false)
    }
  }, [gameId])

  const navigate = useNavigate()

  const _getReviews = async (params: QueryParams): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const reviewsData: ReviewResponse = await getAllReviews(params)
      setReviews(reviewsData.results)
      setRowCount(reviewsData.total)
      setSearch(true)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const _updateReview = async (id: number): Promise<void> => {
    const index = reviews.findIndex(active => active.account.id === id);

    Alert.update('Tem certeza que deseja atualizar essa review?')
      .then(async (result) => {
        if(result.isConfirmed) {
          if (reviews[index].hidden === false) {
            setLoading(true);
            await updateReview(id, { hidden : true })
            Alert.success(
              'Review atualizada com sucesso',
              updateListFromState(id)
            )
          } else {
            setLoading(true);
            await updateReview(id, { hidden : false })
            Alert.success(
              'Review atualizada com sucesso',
              updateListFromState(id)
            )
          }
        }
      })
      .catch((e) => {
        Alert.error(`Ocorreu um erro ao atualizar a review, erro: ${e}`)
      })
  }
  
  const updateListFromState = (id: number) => {
    const index = reviews.findIndex(r => r.account.id === id);
    if (index !== -1) {
      const newReviews = [...reviews];
      newReviews[index].hidden = !newReviews[index].hidden;
      setReviews(newReviews);
      setLoading(false)
    }
  }

  const renderCustomLoadingOverlay = ():JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    );
  }

  const renderActionButtons = (id: number): JSX.Element => {
    const index = reviews.findIndex(active => active.account.id === id);

    return (
      <Button
        onClick={(): Promise<void> => _updateReview(id)}
        color={reviews[index].hidden === false ? 'error' : 'success'}
        variant="contained"
        sx={{ mr: 1 }}
      >
        {reviews[index].hidden === false ? 'Ocultar' : 'Exibir'}
      </Button>
    )
  }

  const _handleSearch = (): void => {
    if (!gameId) return

    const queryParams: QueryParams = {
      page: 1,
      limit: 10,
      gameId: gameId
    }
    _getReviews(queryParams)
  }

  const columns: GridColumns = [
    {
      field: 'rating',
      headerName: 'Rating',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
    },
    {
      field: 'comment',
      headerName: 'Comentário',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 300,
      renderCell: (params) => {
        const { comment } = params.row
        return (
          <Tooltip title={comment} placement="top">
            <Typography variant="caption" display="block" gutterBottom>
              <Button color="success">
                Passe o mouse para ver a mensagem
              </Button>
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: 'createdAt',
      headerName: 'Criado em',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      renderCell: (params) => formatDate(params.row.createdAt, 'PP - KK:mm a'),
    },
    {
      field: 'account',
      headerName: 'Conta',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography variant="caption" display="block" gutterBottom>
            {params.row.account.nickname}
          </Typography>
        )
      },
    },
    {
      field: 'hidden',
      headerName: 'Exibição',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography variant="caption" display="block" gutterBottom>
            {params.row.hidden ? 'Oculto' : 'Exibido'}
          </Typography>
        )
      },
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row.account.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  const renderContent = (): JSX.Element => {
    if (!reviews.length) return <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />

    return (
      <Card
        sx={{
          mt: 4,
          ['& .MuiDataGrid-iconSeparator']: {
            display: 'none'
          }
        }}
      >
        <DataGrid
          paginationMode="server"
          rowCount={rowCount}
          rows={reviews}
          columns={columns}
          loading={refreshDataLoading}
          getRowId={(row) => row.account.id}
          pageSize={10}
          rowsPerPageOptions={[4]}
          autoHeight
          rowHeight={80}
          disableSelectionOnClick
          components={{
            LoadingOverlay: renderCustomLoadingOverlay,
          }}
          sx={{
            ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
              outline: 'none'
            },
            ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
              whiteSpace: 'break-spaces'
            },
          }}
          onPageChange={(): Promise<void> => _getReviews(
            {
              page: 1,
              limit: 10,
              gameId: gameId
            }
          )}
        />
      </Card>
    )
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Reviews">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
      <Styled.SearchForm>
        <TextField
          label="ID do Jogo"
          variant="outlined"
          value={gameId}
          onChange={(e): void => setGameId(Number(e.target.value))}
          disabled={loading}
          placeholder="1" // TODO: Quando existir um endpoint pra listar os jogos, trocar isso por um auto complete.
        />
        <LoadingButton
          variant="contained"
          onClick={(): void => _handleSearch()}
          disabled={loading || !gameId}
          loading={loading}
        >
          <Search /> Buscar
        </LoadingButton>
      </Styled.SearchForm>
      {search && renderContent()}
    </Styled.Container>
  )
}

export { ReviewsList }
