import { CategoryList, CategoryForm } from "@pages/Categories"
import { GamesForm, GamesList } from "@pages/Games"
import { ReviewsList } from "@pages/Reviews"
import { GameTagsForm, GameTagsList } from "@pages/GameTags"
import { GameReviewTagsForm, GameReviewTagsList } from "@pages/GameReviewTags"

type Route = {
  component: JSX.Element
  exact: boolean
  path: string
}

const routes = (): Route[] => [
  // Games
  {
    component: <GamesList />,
    exact: true,
    path: '/apps/mobile/appdiscovery/games/list',
  },
  {
    component: <GamesForm />,
    exact: true,
    path: '/apps/mobile/appdiscovery/games/create',
  },
  {
    component: <GamesForm />,
    exact: true,
    path: '/apps/mobile/appdiscovery/games/:id/edit',
  },

  // Categorias
  {
    component: <CategoryList />,
    exact: true,
    path: '/apps/mobile/appdiscovery/categories/list',
  },
  {
    component: <CategoryForm />,
    exact: true,
    path: '/apps/mobile/appdiscovery/categories/create',
  },
  {
    component: <CategoryForm />,
    exact: true,
    path: '/apps/mobile/appdiscovery/categories/:id/edit',
  },

  // Reviews
  {
    component: <ReviewsList />,
    exact: true,
    path: '/apps/mobile/appdiscovery/reviews/list',
  },

  // Tags
  {
    component: <GameTagsList />,
    exact: true,
    path: '/apps/mobile/appdiscovery/gameTags/list',
  },
  {
    component: <GameTagsForm />,
    exact: true,
    path: '/apps/mobile/appdiscovery/gameTags/create',
  },
  {
    component: <GameTagsForm />,
    exact: true,
    path: '/apps/mobile/appdiscovery/gameTags/:id/edit',
  },

  // Review Tags
  {
    component: <GameReviewTagsList />,
    exact: true,
    path: '/apps/mobile/appdiscovery/gameReviewTags/list',
  },
  {
    component: <GameReviewTagsForm />,
    exact: true,
    path: '/apps/mobile/appdiscovery/gameReviewTags/create',
  },
  {
    component: <GameReviewTagsForm />,
    exact: true,
    path: '/apps/mobile/appdiscovery/gameReviewTags/:id/edit',
  },
]

export default routes