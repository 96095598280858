import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControlLabel,
  Switch,
  Checkbox,
  Stack,
  Box,
  Autocomplete,
  Tabs,
  Tab,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Typography,
  FormControl,
  Chip
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { AddCircleOutlineRounded as AddIcon }  from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

// SERVICES
import { createCategory, getAllCategories, getCategoryById, updateCategory } from '@services/category/CategoryService'

// TYPES
import { CategoryResponse } from '@shared/types/CategoryTypes'

// STYLES
import * as Styled from './CategoryForm.styles'

const CategoryForm = (): JSX.Element => {
  const [name_pt_br, setName_pt_br] = useState<string>('')
  const [name_en_us, setName_en_us] = useState<string>('')
  const [name_es_es, setName_es_es] = useState<string>('')
  const [categoryId, setCategoryId] = useState<number>(undefined)
  const [isSubCategory, setIsSubCategory] = useState<boolean>(false)
  const [categories, setCategories] = useState<CategoryResponse[]>([]) 
  const [loading, setLoading] = useState(false)
  
  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    if (id) {
      _getCategory()
    }
  }, [id])

  useEffect(() => {
    getAllCategories().then((response) => setCategories(response))
  }, [])


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (id) {
      _updateCategory() 
    } else {
      _createCategory()
    }
  }

  const payload = categoryId 
  ? { name_pt_br, name_en_us, name_es_es, categoryId: categoryId } 
  : { name_pt_br, name_en_us, name_es_es,  }
  
  const _createCategory = async (): Promise<void> => {
    try {
      setLoading(true)
      await createCategory(payload)

      Alert.success(
        'Categoria criada com sucesso',
        navigate('/apps/mobile/appdiscovery/categories/list')
      )
    } catch (err) {
      Alert.error(`Ocorreu um erro ao realizar a criação da Categoria. Erro: ${err}`)
    }
  }

  const _updateCategory = async (): Promise<void> => {
    try {
      setLoading(true)
      await updateCategory(Number(id), payload)

      Alert.success(
        'Categoria atualizada com sucesso',
        navigate('/apps/mobile/appdiscovery/categories/list')
      )
    } catch (err) {
      Alert.error(`Ocorreu um erro ao realizar a alteração da categoria. Erro: ${err}`)
    }
  }

  const _getCategory = async (): Promise<void> => {
    try {
      const categoryData = await getCategoryById(Number(id))

      if (categoryData) {
        setName_pt_br(categoryData.name_pt_br)
        setName_en_us(categoryData.name_en_us)
        setName_es_es(categoryData.name_es_es)
        setCategoryId(categoryData.category?.id)
        setIsSubCategory(categoryData.category?.id ? true : false)
      }
    } catch (err) {
      Alert.error(`Ocorreu um erro ao buscar a categoria: ${err}`)
    }
  }

  const Toggle = (): JSX.Element => {
    return (
      <Grid>
        <FormControlLabel
          key="isSubCategory"
          control={<Checkbox />}
          label={`Você está criando uma ${isSubCategory ? 'subcategoria' : 'categoria'}`}
          checked={isSubCategory}
          onChange={(e: any) => setIsSubCategory(e.target.checked)}
        />
      </Grid>
    )
  }

  return (
    <Styled.Container>
        <form onSubmit={handleSubmit}> 
          <Card sx={{ mb: 2 }}>
            <CardHeader title={
              <HeaderTitle title={`${formTitleAction} Categoria`}>
                <AddIcon fontSize="large" style={{ fill: '#0AB844'}}/>
              </HeaderTitle>
            }/>
            <CardContent>
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={4}>
                  <TextField
                    key="name_pt_br"
                    label="Nome da Categoria (PT)"
                    style={{ width: '100%' }}
                    value={name_pt_br}
                    onChange={(e) => setName_pt_br(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key="name_en_us"
                    label="Nome da Categoria (EN)"
                    style={{ width: '100%' }}
                    value={name_en_us}
                    onChange={(e) => setName_en_us(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key="name_es_es"
                    label="Nome da Categoria (ES)"
                    style={{ width: '100%' }}
                    value={name_es_es}
                    onChange={(e) => setName_es_es(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <Toggle />
                  <Typography variant="caption" sx={{ mt: 1 }}>
                    * Caso queira criar uma sub categoria, ative o toggle.
                  </Typography>
                </Grid>
              </Grid>
              {isSubCategory && (
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={categories.find((category: CategoryResponse) => category.id === categoryId)}
                        onChange={(event: any, newValue: CategoryResponse) => {
                          setCategoryId(newValue.id)
                        }}
                        options={categories}
                        getOptionLabel={(option: CategoryResponse) => `${option.name_pt_br} (${option.id})`}
                        renderInput={(params) => (
                          <TextField {...params} label="Categoria Principal" />
                        )}
                      />
                    </FormControl>
                    <Typography variant="caption" sx={{ mt: 1 }}>
                      * Escolha a categoria principal. ex: Game {'>'} Aventura {'>'} {name_pt_br}.
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {isSubCategory && (
                <Grid item xs sx={{ mt: 3, mr: 5 }}>
                  <Box
                    display="flex"
                    justifyContent={{ xs: 'flex-start', sm: 'flex-start' }}
                  >
                    <Button
                      variant="contained"
                      color='error'
                      onClick={(): void => {
                        setIsSubCategory(false)
                        setCategoryId(undefined)
                      }}
                    >
                      Remover categoria principal
                    </Button>
                  </Box>
                </Grid>
                )}
                <Grid item xs sx={{ mt: 3 }}>
                  <Box
                    display="flex"
                    justifyContent={{ xs: 'flex-end', sm: 'flex-end' }}
                  >
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      disabled={loading || !name_pt_br || !name_en_us || !name_es_es}
                      loading={loading}
                      >
                      {buttonLabelAction} categoria
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
    </Styled.Container>
  )
}

export { CategoryForm }
