import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const LinkMenu = styled(Link)`
  color: #fff !important;
  text-decoration: none;
`
export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const MenuContent = styled.div`
  overflow-x: hidden;
  height: 100%;
  max-height: calc(100vh - 150px);
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    border-radius: 3rem;
    background: #31669a;
  }
  ::-webkit-scrollbar-thumb  {
    background: #10579c;
    border-radius: 3rem;
  }
`

export const FooterContainer = styled.div`
  position: fixed;
  width: 200px;
  height: 50px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0.5rem 0.75rem;
`

export const Divider = styled.div`
  border-top: 0.5px solid #0CA143;
  margin-bottom: 0.75rem;
`

export const Footer = styled.span`
  color: #fff;
  text-decoration: none;
  bottom: 2rem;
  font-weight: normal;
  font-size: 0.75rem;
  text-align: center;
  display: inline-block;
`

export const FooterLink = styled.a`
  color: #99F09F;
  text-decoration: none;
  align-self: center;
  font-weight: normal;
  font-size: 0.75rem;
`