// API
import { mobileApi } from '@shared/api/mobile/mobileApi'

// TYPES
import {
  ReviewResponse,
  QueryParams,
  ReviewPayload
} from '@shared/types/ReviewTypes'

const getAllReviews = ({ limit, page, gameId }: QueryParams): Promise<ReviewResponse> => {
  return mobileApi.get(`/backoffice/games/reviews?limit=${limit}&page=${page}&gameId=${gameId}`)
}

const updateReview = (reviewId: number, data: ReviewPayload): Promise<ReviewResponse> => {
  return mobileApi.patch(`/backoffice/games/reviews/${reviewId}`, data)
}

export {
  getAllReviews,
  updateReview,
}
