// API
import { mobileApi } from '@shared/api/mobile/mobileApi'

// TYPES
import {
  GameReviewTag
} from '@shared/types/GameReviewTagTypes'

const getAllGameReviewTags = (): Promise<GameReviewTag[]> => {
  return mobileApi.get(`/backoffice/games/reviews/tags`)
}

const createGameReviewTag = (data: Pick<GameReviewTag, 'name_pt_br'>): Promise<GameReviewTag> => {
  return mobileApi.post(`/backoffice/games/reviews/tags`, data)
}

const updateGameReviewTag = (gameReviewTagId: number, data: Pick<GameReviewTag, 'name_pt_br'>): Promise<GameReviewTag> => {
  return mobileApi.patch(`/backoffice/games/reviews/tags/${gameReviewTagId}`, data)
}

const getGameReviewTagById = (gameReviewTagId: number): Promise<GameReviewTag> => {
  return mobileApi.get(`/backoffice/games/reviews/tags/${gameReviewTagId}`)
}

const deleteGameReviewTag = (gameReviewTagId: number): Promise<void> => {
  return mobileApi.delete(`/backoffice/games/reviews/tags/${gameReviewTagId}`)
}

export {
  getAllGameReviewTags,
  createGameReviewTag,
  updateGameReviewTag,
  getGameReviewTagById,
  deleteGameReviewTag,
}
