import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// HELPERS
import RichTextEditor from '@shared/helpers/RichTextEditor'
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Avatar,
  Divider,
  Stack,
  Box,
  Autocomplete,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Typography,
  Chip,
  Switch,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { AddCircleOutlineRounded as AddIcon }  from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

// SERVICES
import { createGame, getGameById, getGameData, updateGame } from '@services/mobile/MobileService'
import { getAllCategories } from '@services/category/CategoryService'
import { getAllGameTags } from '@services/gameTag/GameTagService'
import { getYoutubeChannelId } from '@services/youtube/YoutubeService'

// ICONS
import { Search } from '@mui/icons-material'

// TYPES
import {
  DeviceProps,
  GameData,
  TweetsIndexationType,
  YoutubeVideosIndexationType,
  NewsFeedSource,
  initialGameState
} from '@shared/types/SharedTypes'
import { Category, CategoryResponse } from '@shared/types/CategoryTypes'
import { GameTag } from '@shared/types/GameTagTypes'

// STYLES
import * as Styled from './GamesForm.styles'

const GamesForm = (): JSX.Element => {
  const [game, setGame] = useState<GameData>(initialGameState)
  const [rank, setRank] = useState<number>(undefined)
  const [twitchId, setTwitchId] = useState<number>(undefined)
  const [categoriesList, setCategoriesList] = useState<Category[]>([])
  const [gameTagsList, setGameTagsList] = useState<GameTag[]>([])
  const [androidGameId, setAndroidGameId] = useState<string>('')
  const [appleGameId, setAppleGameId] = useState<string>('')
  const [androidGameData, setAndroidGameData] = useState<DeviceProps>()
  const [appleGameData, setAppleGameData] = useState<DeviceProps>()
  const [loading, setLoading] = useState(false)

  const [newTag, setNewTag] = useState<string>('')

  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'
  const newsMetadata = game?.newsFeedSettings?.[0]?.metadata?.values

  useEffect(() => {
    if (id) {
      _getGame()
    }
  }, [id])

  useEffect(() => {
    getAllCategories().then((response) => setCategoriesList(response))
    getAllGameTags().then((response) => setGameTagsList(response))
  }, [])
  
  const updateGameState = (
    gameKey: string,
    value: any,
  ): void => {
    setGame((prev) => {
      return { ...prev, [gameKey]: value }
    })
  }

  const updateNewsGameState = (
    value: string,
    arrayIndex: number
    ): void => {
    setGame(oldConfig => {
      const newSources = oldConfig.newsFeedSettings
      const newSource = value === 'youtube' ? {
        id: newSources[arrayIndex].id,
        metadata: newSources[arrayIndex].metadata,
        source: value
      } : {
        id: newSources[arrayIndex].id,
        metadata: newSources[arrayIndex].metadata,
        source: value,
      }
      newSources[arrayIndex] = newSource
      const newGame = {
        ...oldConfig,
        newsFeedSettings: newSources
      }
      return newGame
    })
  }

  const updateNewsGameMetadataState = (
    key: string,
    value: string,
    index: number
  ): void => {
    setGame(oldConfig => {
      const newNewsFeeds = oldConfig.newsFeedSettings
      const newMeta = { ...newNewsFeeds[index].metadata }
      newMeta[key] = value
      newNewsFeeds[index].metadata = newMeta

      return {
        ...oldConfig,
        newsFeedSettings: newNewsFeeds,
      }
    })
  }

  const updateAndroidState = (
    key: string,
    value: string | string[],
    ): void => {
    setAndroidGameData(oldConfig => {
      const newAndroids = oldConfig
      const newAndroid = {
        ...newAndroids,
        [key]: value,
      }

      return {
        ...oldConfig,
        ...newAndroid
      }
    })
  }

  const updateAppleState = (
    key: string,
    value: string | string[],
    ): void => {
    setAppleGameData(oldConfig => {
      const newApples = oldConfig
      const newApple = {
        ...newApples,
        [key]: value,
      }

      return {
        ...oldConfig,
        ...newApple
      }
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (id) {
      _updateGame() 
    } else {
      _createGame()
    }
  }

  const payload = {
    title_pt_br: game?.title_pt_br,
    title_en_us: game?.title_en_us,
    title_es_es: game?.title_es_es,
    imageUrl: game?.imageUrl,
    backgroundUrl: game?.backgroundUrl,
    rank: rank,
    twitchId: twitchId,
    description_pt_br: game?.description_pt_br,
    description_en_us: game?.description_en_us,
    description_es_es: game?.description_es_es,
    publisher: game?.publisher,
    android: {
      idStore: androidGameData?.idStore || '',
      url: androidGameData?.url || '',
      installs: androidGameData?.installs || '',
      reviews: androidGameData?.reviews || null,
      score: androidGameData?.score || null,
      ratings: androidGameData?.ratings || null,
      size: androidGameData?.size || null,
      supportedDevices: androidGameData?.supportedDevices || [],
      contentRating: androidGameData?.contentRating
    },
    ios: {
      idStore: appleGameData?.idStore || '',
      url: appleGameData?.url || '',
      installs: appleGameData?.installs || '',
      reviews: appleGameData?.reviews || null,
      score: appleGameData?.score || null,
      ratings: appleGameData?.ratings || null,
      size: appleGameData?.size || null,
      supportedDevices: appleGameData?.supportedDevices || [],
      contentRating: appleGameData?.contentRating
    },
    categoriesIds: game?.categories?.map(c => c.id) || [],
    tagsIds: game?.tags?.map(tag => tag.id) || [],
    newsFeedSettings: game?.newsFeedSettings.filter(news => news.source !== ''),
    isActive: game?.isActive,
    hasNetzyn: game?.hasNetzyn
  }

  if (!payload.android?.url) delete payload.android
  if (!payload.ios?.url) delete payload.ios
  if (payload.newsFeedSettings.length < 1) delete payload.newsFeedSettings

  const _createGame = async (): Promise<void> => {
    try {
      await createGame(payload)

      Alert.success(
        'Game criado com sucesso',
        navigate('/apps/mobile/appdiscovery/games/list')
      )
    } catch (err) {
      Alert.error(`Ocorreu um erro ao realizar a criação do game. Erro: ${err.errors}`)
    }
  }

  const _updateGame = async (): Promise<void> => {
    try {
      await updateGame(id, payload)

      Alert.success(
        'Game atualizado com sucesso',
        navigate('/apps/mobile/appdiscovery/games/list')
      )
    } catch (err) {
      Alert.error(`Ocorreu um erro ao realizar a alteração do game. Erro: ${err.errors}`)
    }
  }

  const _getGame = async (): Promise<void> => {
    try {
      const gameData = await getGameById(id)

      if (gameData) {
        setAndroidGameData(gameData.android)
        setAppleGameData(gameData.ios)
        setRank(gameData.rank)
        setTwitchId(gameData.twitchId)
        setGame({
          title_pt_br: gameData.title_pt_br,
          title_en_us: gameData.title_en_us,
          title_es_es: gameData.title_es_es,
          imageUrl: gameData.imageUrl,
          backgroundUrl: gameData.backgroundUrl,
          rank: gameData.rank,
          twitchId: gameData.twitchId,
          description_pt_br: gameData.description_pt_br,
          description_en_us: gameData.description_en_us,
          description_es_es: gameData.description_es_es,
          publisher: gameData.publisher,
          android: gameData.android,
          ios: gameData.ios,
          categories: gameData.categories as Category[],
          tags: gameData.tags as GameTag[],
          newsFeedSettings: [...gameData.newsFeedSettings],
          isActive: gameData.isActive,
          hasNetzyn: gameData.hasNetzyn
        })
      }
    } catch (err) {
      Alert.error(`Ocorreu um erro ao buscar o game: ${err.errors}`)
    }
  }

  const _getGameData = async (androidGameId: string, appleGameId: string): Promise<void> => {
    if (androidGameId !== '') {
      getGameData('android', androidGameId).then((response) => {
        setAndroidGameData(response)
      }).catch( (err) => {
        Alert.error(`Ocorreu um erro ao buscar o game android: ${err}`)
      }).finally(() => {
        setLoading(false)
      })
    }

    if (appleGameId !== '') {
      getGameData('ios', appleGameId).then((response) => {
        setAppleGameData(response)
      }).catch( (err) => {
        Alert.error(`Ocorreu um erro ao buscar o game ios: ${err}`)
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    setGame(prev => ({
      title_pt_br: androidGameData?.title || appleGameData?.title,
      title_en_us: androidGameData?.title || appleGameData?.title,
      title_es_es: androidGameData?.title || appleGameData?.title,
      imageUrl: androidGameData?.imageUrl || appleGameData?.imageUrl,
      backgroundUrl: androidGameData?.backgroundUrl || appleGameData?.backgroundUrl,
      rank,
      twitchId,
      description_pt_br: androidGameData?.description || appleGameData?.description,
      description_en_us: androidGameData?.description || appleGameData?.description,
      description_es_es: androidGameData?.description || appleGameData?.description,
      publisher: androidGameData?.publisher || appleGameData?.publisher,
      isActive: prev.isActive,
      android: {
        idStore: androidGameData?.idStore || '',
        url: androidGameData?.url || '',
        installs: androidGameData?.installs || '',
        reviews: androidGameData?.reviews || null,
        score: androidGameData?.score || null,
        ratings: androidGameData?.ratings || null,
        size: androidGameData?.size || null,
        supportedDevices: androidGameData?.supportedDevices || [],
        contentRating: androidGameData?.contentRating || 0,
      },
      ios: {
        idStore: appleGameData?.idStore || '',
        url: appleGameData?.url || '',
        installs: appleGameData?.installs || '',
        reviews: appleGameData?.reviews || null,
        score: appleGameData?.score || null,
        ratings: appleGameData?.ratings || null,
        size: appleGameData?.size || null,
        supportedDevices: appleGameData?.supportedDevices || [],
        contentRating: appleGameData?.contentRating || 0
      },
      newsFeedSettings: [...game.newsFeedSettings],
      hasNetzyn: false
    })
  )}, [androidGameData, appleGameData])

  const _handleSearch = (): void => {
    _getGameData(androidGameId, appleGameId)
  }
  
  const renderNewsFeedSourceOptions = Object.keys(NewsFeedSource).map(key => (
    <MenuItem
      value={key}
      key={key}
    >
      {NewsFeedSource[key]}
    </MenuItem>
  ))

  const renderTweetsIndexationTypeOptions = Object.keys(TweetsIndexationType).map(key => (
    <MenuItem
      value={key}
      key={key}
    >
      {TweetsIndexationType[key]}
    </MenuItem>
  ))

  const renderYoutubeVideosIndexationTypeOptions = Object.keys(YoutubeVideosIndexationType).map(key => (
    <MenuItem
      value={key}
      key={key}
    >
      {YoutubeVideosIndexationType[key]}
    </MenuItem>
  ))
  
  const handleAddNewsFeed = (): void => {
    newsMetadata.push(newTag)

    setNewTag('');
  }

  const handleAddNewNews = (tag: string): void => {
    setNewTag(tag)
  }

  const handleAddNewsSource = (): void => {
    setGame(prev => ({
      ...prev,
      newsFeedSettings: [
        ...prev.newsFeedSettings,
        ...initialGameState.newsFeedSettings
      ]
    }))
  }

  const handleRemoveNewsSource = (index: number): void => {
    const newFeed = game.newsFeedSettings
    newFeed.splice(index, 1)
    setGame(prev => ({
      ...prev,
      newsFeedSettings: [...newFeed]
    }))
  }

  const handleDeleteNewsFeed = (index: number): void => {
    newsMetadata.splice(index, 1)
    setGame({ ...game })
  }

  const renderNewsFeedSettings = (): JSX.Element => {
    return (
      <Box>
        <Typography variant="caption" display="block" gutterBottom>
          Notícias
        </Typography>
        <>
          {game.newsFeedSettings?.map((newsFeed, index) => (
            <Styled.NewsContainer key={index}>
            <Grid
              container
              spacing={2}
              key={index}
            >
              <Grid item xs={4}>
                <Select
                  fullWidth={true}
                  key="source"
                  value={newsFeed.source}
                  onChange={(e) => updateNewsGameState(e.target.value, index)}
                >
                  {renderNewsFeedSourceOptions}
                </Select>
                <Typography variant="caption" display="block" gutterBottom>
                  * Fonte da noticia
                </Typography>
              </Grid>

              {newsFeed.source === 'twitter' && (
                <>
                  <Grid item xs={4}>
                    <TextField
                      key="username"
                      label="username"
                      style={{ width: '100%' }}
                      value={newsFeed.metadata.username}
                      onChange={(e) => updateNewsGameMetadataState('username', e.target.value, index)}
                    />
                    <Typography variant="caption" display="block" gutterBottom>
                      * Nome de usuário da Source. ex: subwaysurfers
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Select
                      fullWidth={true}
                      key="indexationType"
                      value={newsFeed.metadata.indexationType}
                      onChange={(e) => updateNewsGameMetadataState('indexationType', e.target.value, index)}
                    >
                      {renderTweetsIndexationTypeOptions}
                    </Select>
                    <Typography variant="caption" display="block" gutterBottom>
                      * A indexação pode ser feita por hashtag ou palavra-chave, ou TODOS.
                    </Typography>
                  </Grid>
                </>
              )}

              {newsFeed.source === 'youtube' && (
                <>
                  <Grid item xs={4}>
                    <TextField
                      key="channelName"
                      label="Channel Name"
                      style={{ width: '100%' }}
                      // value={}
                      onBlur={async (e) => {
                        const channelName = e.target.value
                        const channelId = channelName && await getYoutubeChannelId(channelName)
                        if(channelId) {
                          updateNewsGameMetadataState('channelId', channelId, index)
                        }
                        return undefined
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      key="channelId"
                      label="channelId"
                      style={{ width: '100%' }}
                      value={newsFeed.metadata.channelId}
                      placeholder='UCGsYpTrwc-sPjlyoSGPbcbw'
                      onChange={(e) => updateNewsGameMetadataState('channelId', e.target.value, index)}
                    />
                    <Typography variant="caption" display="block" gutterBottom>
                      * ID do canal do youtube ex: UCGsYpTrwc-sPjlyoSGPbcbw
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container alignItems="center" justifyContent="center" key={index}>
            {newsFeed.source && newsFeed.metadata?.indexationType && newsFeed.metadata.indexationType !== 'ALL' &&
              <>
                <Grid item xs={4}>
                  <Select
                    fullWidth={true}
                    key="indexationType"
                    value={newsFeed.metadata.indexationType}
                    onChange={(e) => updateNewsGameMetadataState('indexationType', e.target.value, index)}
                  >
                    {renderYoutubeVideosIndexationTypeOptions}
                  </Select>
                  <Typography variant="caption" display="block" gutterBottom>
                    * A indexação pode ser feita por hashtag ou palavra-chave, ou TODOS.
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Tag"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleAddNewNews(e.target.value)}
                  />
                  <Typography variant="caption" display="block" gutterBottom>
                    * Um valor precisa ser definido caso o tipo de indexação seja "Hashtag" ou Palavra-chave
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    color='success'
                    variant='contained'
                    sx={{ ml: 2, mb: 3 }}
                    onClick={(): void => handleAddNewsFeed()}
                  >
                    Adicionar Tag
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {newsMetadata && newsMetadata.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      key={index}
                      sx={{ mt: 2, ml: 2 }}
                      onDelete={() => handleDeleteNewsFeed(index)}
                    />
                  ))}
                </Grid>
              </>
            }
              <Grid item xs={12}>
                <Button
                  color='error'
                  variant='contained'
                  sx={{ mb: 1 }}
                  onClick={(): void => handleRemoveNewsSource(index)}
                >
                  Excluir fonte
                </Button>
              </Grid>
            </Grid>
          </Styled.NewsContainer>
          ))}
          <Grid item xs={1}>
            <Button
              color='success'
              variant='outlined'
              onClick={(): void => handleAddNewsSource()}
            >
              Nova fonte de notícia
            </Button>
          </Grid>
        </>
      </Box>
    )
  }
  
  const isShowTables = androidGameData || appleGameData

  return (
    <Styled.Container>
      <HeaderTitle title={`${formTitleAction} Game`}>
        <AddIcon fontSize="large" style={{ fill: '#0AB844'}}/>
      </HeaderTitle>
      <Styled.SearchForm>
        <TextField
          label="ID do jogo na loja ANDROID"
          variant="outlined"
          value={androidGameId}
          onChange={(e): void => setAndroidGameId(e.target.value)}
          disabled={loading}
          placeholder="com.org.gamename"
        />
        <TextField
          label="ID do jogo na loja IOS"
          variant="outlined"
          value={appleGameId}
          onChange={(e): void => setAppleGameId(e.target.value)}
          disabled={loading}
          placeholder="512939461"
        />
        <LoadingButton
          variant="contained"
          onClick={(): void => _handleSearch()}
          disabled={loading}
          loading={loading}
        >
          <Search /> Buscar
        </LoadingButton>
      </Styled.SearchForm>
        {isShowTables && (
          <form onSubmit={handleSubmit}> 
            <Card sx={{ mb: 2 }}>
              <Grid container spacing={2} sx={{ mt: 0.2, ml: 0 }}>
                <CardHeader title={
                  <HeaderTitle title={game?.title_pt_br}>
                    <Avatar alt={game?.title_pt_br} src={game?.imageUrl} />
                  </HeaderTitle>
                }/>
                <Grid sx={{ mt: 'auto', mb: 'auto' }}>
                  <Typography variant="caption" color={game.isActive ? 'grey' : 'white'}>
                    Desativado
                  </Typography>
                  <Switch
                    checked={game.isActive}
                    onChange={() => updateGameState('isActive', !game.isActive)}
                    color="primary"
                  />
                  <Typography variant="caption" color={game.isActive ? 'white' : 'grey'}>
                    Ativado
                  </Typography>
                </Grid>
                <Grid sx={{ mt: 'auto', mb: 'auto', ml: 2 }}>
                <Typography variant="caption" color={game.hasNetzyn ? 'white' : 'grey'}>
                    Netzyn desativado
                  </Typography>
                  <Switch
                    checked={game.hasNetzyn}
                    onChange={() => updateGameState('hasNetzyn', !game.hasNetzyn)}
                    color="primary"
                  />
                  <Typography variant="caption" color={game.hasNetzyn ? 'white' : 'grey'}>
                    Netzyn ativado
                  </Typography>
                </Grid>
                <Grid item xs sx={{ mt: 3, mr: 5 }}>
                  <Box
                    display="flex"
                    justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                    >
                    <Button variant="contained" type="submit">
                      {buttonLabelAction} Game
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <CardContent>
                <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
                  <Box sx={{ width: '50%' }}>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                      <Grid item xs={4}>
                        <TextField
                          key="gameInfo"
                          label="Nome do Jogo (PT)"
                          style={{ width: '100%' }}
                          value={game?.title_pt_br}
                          onChange={(e) => updateGameState('title_pt_br', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          key="gameInfo"
                          label="Nome do Jogo (EN)"
                          style={{ width: '100%' }}
                          value={game?.title_en_us}
                          onChange={(e) => updateGameState('title_en_us', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          key="gameInfo"
                          label="Nome do Jogo (ES)"
                          style={{ width: '100%' }}
                          value={game?.title_es_es}
                          onChange={(e) => updateGameState('title_es_es', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                      
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                      <Grid item xs={4}>
                        <TextField
                          key="publisher"
                          label="Publisher"
                          style={{ width: '100%' }}
                          value={game.publisher}
                          onChange={(e) => updateGameState('publisher', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          key="rank"
                          label="Rank"
                          type="number"
                          inputProps={{
                            min: 0
                          }}
                          style={{ width: '100%' }}
                          value={rank}
                          onChange={(e) => setRank(Number(e.target.value))}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          value={game.tags as GameTag[] || []}
                          onChange={(event: any, newValue) => {
                            updateGameState('tags', newValue)
                          }}
                          options={gameTagsList}
                          getOptionLabel={(option: GameTag) => `${option.name}`}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField {...params} label="Tags" />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                      <Grid item xs={12}>
                        <InputLabel>Descrição (PT)</InputLabel>
                        <RichTextEditor
                          height={425}
                          value={game.description_pt_br}
                          outputFormat="html"
                          onChange={(content) =>
                            updateGameState('description_pt_br', content)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                      <Grid item xs={12}>
                        <InputLabel>Descrição (EN)</InputLabel>
                        <RichTextEditor
                          height={425}
                          value={game.description_en_us}
                          outputFormat="html"
                          onChange={(content) =>
                            updateGameState('description_en_us', content)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                      <Grid item xs={12}>
                        <InputLabel>Descrição (ES)</InputLabel>
                        <RichTextEditor
                          height={425}
                          value={game.description_es_es}
                          outputFormat="html"
                          onChange={(content) =>
                            updateGameState('description_es_es', content)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: '50%' }}
                  >
                    <Grid container alignItems="center" justifyContent="center">
                      <Typography variant="caption" display="block" gutterBottom>
                        Background
                      </Typography>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="center">
                      <Avatar sx={{ maxWidth: 520, maxHeight: 250, width: '100%', height: '100%' }} variant="square" src={game.backgroundUrl} />
                    </Grid>

                    <Divider sx={{ mt: 2, mb: 2 }} />


                    <Grid container spacing={2} sx={{ mb: 3 }}>
                      <Grid item xs={4}>
                        <TextField
                          key="twitchId"
                          label="Twitch ID"
                          type="number"
                          inputProps={{
                            min: 0
                          }}
                          style={{ width: '100%' }}
                          value={twitchId}
                          onChange={(e) => setTwitchId(Number(e.target.value))}
                        />
                      </Grid>
                    </Grid>

                    {renderNewsFeedSettings()}
                  </Box>
                </Stack>
              </CardContent>
            </Card>
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
                  <Box>
                    <Card>
                      <CardHeader title={
                        <HeaderTitle title={`${game?.title_pt_br} - Android`}>
                          <Avatar alt={game?.title_pt_br} src={game.imageUrl} />
                        </HeaderTitle>
                      }/>
                      {androidGameData && 
                        <CardContent>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                              <Grid item xs={3}>
                                <TextField
                                  key="idStore"
                                  label="ID na loja"
                                  style={{ width: '100%' }}
                                  value={androidGameData?.idStore}
                                  onChange={(e) => updateAndroidState('idStore', e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  key="url"
                                  label="URL"
                                  style={{ width: '100%' }}
                                  value={androidGameData?.url}
                                  onChange={(e) => updateAndroidState('url', e.target.value)}

                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  key="installs"
                                  label="Instalações"
                                  style={{ width: '100%' }}
                                  value={androidGameData?.installs}
                                  onChange={(e) => updateAndroidState('installs', e.target.value)}

                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  key="reviews"
                                  label="Reviews"
                                  style={{ width: '100%' }}
                                  value={androidGameData?.reviews}
                                  onChange={(e) => updateAndroidState('reviews', e.target.value)}

                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                              <Grid item xs={4}>
                                <TextField
                                  key="score"
                                  label="Score"
                                  style={{ width: '100%' }}
                                  value={androidGameData?.score}
                                  onChange={(e) => updateAndroidState('score', e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  key="ratings"
                                  label="Ratings"
                                  style={{ width: '100%' }}
                                  value={androidGameData?.ratings}
                                  onChange={(e) => updateAndroidState('ratings', e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  key="contentRating"
                                  label="Content Rating"
                                  style={{ width: '100%' }}
                                  value={androidGameData?.contentRating}
                                  onChange={(e) => updateAndroidState('contentRating', e.target.value)}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                              <Grid item xs={12}>
                                <Autocomplete
                                  multiple
                                  value={androidGameData?.supportedDevices || []}
                                  onChange={(event: any, newValue: string[] | null) => {
                                    updateAndroidState('supportedDevices', newValue || [])
                                  }}
                                  options={androidGameData?.supportedDevices || []}
                                  getOptionLabel={(option: string) => `${option}`}
                                  filterSelectedOptions
                                  renderInput={(params) => (
                                    <TextField {...params} label="Dispositivos suportados" />
                                  )}
                                />
                              </Grid>
                            </Grid>
                        </CardContent>
                      }
                    </Card>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
                  <Box>
                    <Card>
                      <CardHeader title={
                        <HeaderTitle title={`${game?.title_pt_br} - IOS`}>
                          <Avatar alt={game?.title_pt_br} src={game.imageUrl} />
                        </HeaderTitle>
                      }/>
                      {appleGameData && 
                        <CardContent>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                              <Grid item xs={3}>
                                <TextField
                                  key="idStore"
                                  label="ID na loja"
                                  style={{ width: '100%' }}
                                  value={appleGameData?.idStore}
                                  onChange={(e) => updateAppleState('idStore', e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  key="url"
                                  label="URL"
                                  style={{ width: '100%' }}
                                  value={appleGameData?.url}
                                  onChange={(e) => updateAppleState('url', e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  key="reviews"
                                  label="Reviews"
                                  style={{ width: '100%' }}
                                  value={appleGameData?.reviews}
                                  onChange={(e) => updateAppleState('reviews', e.target.value)}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                              <Grid item xs={4}>
                                <TextField
                                  key="score"
                                  label="Score"
                                  style={{ width: '100%' }}
                                  value={appleGameData?.score}
                                  onChange={(e) => updateAppleState('score', e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  key="contentRating"
                                  label="Content Rating"
                                  style={{ width: '100%' }}
                                  value={appleGameData?.contentRating}
                                  onChange={(e) => updateAppleState('contentRating', e.target.value)}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                              <Grid item xs={12}>
                                <Autocomplete
                                  multiple
                                  value={appleGameData?.supportedDevices || []}
                                  onChange={(event: any, newValue: string[] | null) => {
                                    updateAppleState('supportedDevices', newValue || [])
                                  }}
                                  options={appleGameData?.supportedDevices || []}
                                  getOptionLabel={(option: string) => `${option}`}
                                  filterSelectedOptions
                                  renderInput={(params) => (
                                    <TextField {...params} label="Dispositivos suportados" />
                                  )}
                                />
                              </Grid>
                            </Grid>
                        </CardContent> 
                      }
                    </Card>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </form>
        )}
    </Styled.Container>
  )
}

export { GamesForm }
